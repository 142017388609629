<i18n src="@/locales/testimonial65.json" />
<template>
  <div class="fixed-background">
    <ImanTestimonialHeader :img="img">
      <h1>{{ $t('h1_title_1') }}</h1>
      <p>
        {{ $t('header_p1') }}
      </p>
    </ImanTestimonialHeader>
    <ImanTestimonialMessage type="is-info">
      <span v-html="$t('message_1')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('h2_title_1')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial65/parodontite-grave-dents-dechaussees.jpg"
            alt="Déchaussement des dents et rétractation de la gencive"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p1') }}
          </p>
        </div>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('h2_title_2')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial65/implant-basal-parodontite-gencive.jpg"
            alt="Avis sur les implants dentaires non invasifs"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p2_1') }}
          </p>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial65/prothèse-fixe-implant-basal.jpg"
            alt="Pose d'implants dentaires sur gencive rétractée"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p2_2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
        </div>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('h2_title_3')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial65/dr-ihde-belgrade.jpg"
            alt="Avis sur les soins dentaires à l'étranger"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p3') }}
          </p>
        </div>
      </div>
    </ImanTestimonialArticle>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Testimonial65",
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video: {
        src: 'https://www.youtube.com/embed/js2odpUoezQ'
      },
      img: {
        path: 'testimonial/testimonial65/miniature.jpg',
        alt: ''
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/miniature_65.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
.card {
  margin: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.70);
  .card-header-title {
    background-color: rgba(224, 32, 64, 0.70);
    font-family: 'Comfortaa', cursive;
    color: white;
  }
  .card-content {
    text-align: justify;
  }
}
</style>
